@if (userProfileChecked && response && translitTriggered) {

<mat-drawer-container>
  <mat-drawer #drawer
    [mode]="over1200 ? 'side' : 'over'"
    [opened]="over1200">
    <app-drawer-sidebar />
  </mat-drawer>
  <mat-drawer-content>
    <app-drawer-content />
  </mat-drawer-content>

</mat-drawer-container>

} @else {
<div id="splash-screen">
  @if (themeService.isLightMode()) {
  <img loading="lazy"
    src="assets/images/gallery-logo-text-outline.png"
    alt="Logo Galerija" />
  } @else {
  <img loading="lazy"
    src="assets/images/gallery-logo-text-outline.png"
    alt="Logo Galerija" />
  }

  <div class="spinner-wrapper">
    <div class="loader">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
    </div>

    <span class="city-mixer-wrapper">
      <div class="city-mixer blue">{{currentCity | translit}}</div>
    </span>
  </div>

</div>
}