<h3 class="search-header">
  {{'Претрага личности и градова' | translit}}
  <mat-icon class="close-button"
    (click)="onClose()">close</mat-icon>
</h3>

<div class="category-button-div">
  <button mat-stroked-button
    class="category-button"
    (click)="goToCategories()">
    {{'Категорије' | translit}}
  </button>
  <button mat-stroked-button
    class="tag-button"
    (click)="goToTags()">
    {{'Тагови' | translit}}
  </button>
  <button mat-stroked-button
    class="birthday-button"
    (click)="goToSurnames()">
    {{'Презимена' | translit}}
  </button>
</div>


@if (recentSearches.length) {
<div class="recent-searches">
  @for (recent of recentSearches; track recent) {
  <img [src]="recent.imagePath"
    loading="lazy"
    [alt]="recent.commonName"
    (click)="onItem(recent)"
    class="recent-image"
    appDefaultLogo>
  }
</div>
}

<form class="search-form">
  <mat-form-field class="search-field"
    appearance="outline">

    <!-- Field Label -->
    <mat-label class="search-label">{{'Претрага' | translit}}</mat-label>

    <!-- Search Input -->
    <input matInput
      #searchInput
      class="search-input"
      [matAutocomplete]="auto"
      (keydown.enter)="$event.preventDefault()"
      [formControl]="control">

    <!-- Autocomplete dropdown -->
    <mat-autocomplete #auto="matAutocomplete"
      autoActiveFirstOption>
      @for (item of (filteredItems$ | async); track item.slug) {
      <mat-option class="autocomplete-option"
        (onSelectionChange)="onItem(item)"
        [value]="item.name">
        <img [alt]="item.name | translit"
          appDefaultLogo
          class="item-image"
          [src]="item.imagePath">
        <span>
          <div class="item-name">
            {{item.commonName | translit}}
          </div>
          <div>
            <small>
              {{item.fullTitle | translit}}
              @if (item.birthDeath) {
              <span>
                ({{item.birthDeath | translit}})
              </span>
              } @else if(item.title) {
              презиме
              }
            </small>
          </div>
        </span>
      </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>

  <!-- hint -->
  @if (showThreeLetterWarning) {
  <div class="warning-message info">
    * {{'Минимум три слова потребна' | translit}}
  </div>
  }
  @if (showNoResultWarning) {
  <div class="warning-message">
    * {{'Нема резултата који одговарају претрази.' | translit}}
  </div>
  }
</form>